//General
@import '../../partials/general';
//Partials
@import '../../partials/mobile/general';
@import '../../partials/mobile/header';
@import '../../partials/mobile/footer';
//Widgets
@import '../../widgets/highlights/mobile';
//Store
@import '../../store/showcase/mobile';

@mixin background-cover($height, $background){
    width: 100%;
    height: $height;
    position:relative;
    background: url($background) center center;
    background-size: cover;
}

main{
    background-color: #fff;
}

.rotator>img{
    display: inherit;
    width: 100%;
}

.slick-dots {
    position: absolute;
    text-align: center;
    width: 100%;
    bottom: 10px;
    &>li{
        @include carousel-selector(#fff);
        &.slick-active{
            @include carousel-selector(#95c731);
        }
        button {
            display: none;
        }
    }
}

#rotator-cover-large {

    .slider{
        background-color: #262626;
        &.vo-carousel-load-fix{
            height: 250px;
            opacity: 1;
        }
    }

    .background-cover{
        width:100%;

    }
    .background-container{
        position: relative;
        margin: auto 10px;

        .carousel-selector{
            top: -25%;
        }
    }
}

.news{
    min-height: 100px;
    background-color: #fff;
    padding: 25px 15px;
    .arrow{
        @include arrow;
    }

    a, a:hover, a:active{
        text-decoration: none;
        color: inherit;
    }

    .frame.vo-carousel-load-fix{
        overflow: hidden;
    }

    .news-item {
        display: inline-block;

        &.vo-carousel-load-fix{
            white-space: nowrap;
            max-height: 75px;
        }

        .date{
            font-weight: 600;
        }

        a {
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            overflow: hidden;
            line-height: 15px;
        }
    }
}

.slider-t2{
    margin-bottom: 20px;
    .background-container{
        position: relative;
        .description{
            background-color: #000;
            padding: 20px 0 30px 0;
            text-align: center;
            min-height: 94px;
            h2{
                font-size: 14px;
                line-height: 18px;
                text-transform: uppercase;
                font-weight: bold;
                color: #fff;
                padding: 0 10px;
            }
            h3{
                font-size: 14px;
                line-height: 18px;
                color: #fff;
            }
        }
        .carousel-selector{
            position: absolute;
            bottom: 8px;
        }
    }

    .carousel-slider{
        &.vo-carousel-load-fix {
            max-height: 192px;
            overflow: hidden;
            white-space: nowrap;
            display: inline-block;
        }
    }
}

.espaco-lingua-portuguesa{
    background: url('../../../images/lp_bg_mobile.png') center center;
    background-size: cover;
    width:100%;
    padding: 30px 0px;
    h4{
        text-align: center;
        font-weight: bold;
        font-size: 20px;
        width: 100%;
        color: #fff;
    }

    form.single-field{
        position:relative;
        margin: 15px 0;
        input[type=text] {
            display: inline-block;
            width: 100%;
            font-size: 14px;
            height: 50px;
            padding: 17px 60px 15px 30px;
            border: none;
            color: #000;
            border-radius: 0;
            @include placeholder {
                text-align:center;
                color: #a4a4a4;
                font-size: 11px;
                text-transform: uppercase;
                padding-top: 2px;
            }
        }
        button{
            position: absolute;
            top: 0;
            bottom: 0;
            right: 15px;
            color:#fff;
            width: 30px;
            background-color: #fda100;
            border: none;
            span{
                font-size: 50px;
                position: absolute;
                right: -10px;
                top: 0;
            }
        }
    }

    a.single-button{
        display: block;
        margin: 15px 0;
        width: 100%;
        font-size: 11px;
        color: #fff;
        padding: 20px 0;
        line-height: 10px;
        text-align: center;
        text-transform: uppercase;
        background-color: #95c731;
        border: none;
        position: relative;
        span{
            color:#fff;
            font-size: 50px;
            position: absolute;
            top: 0;
            right: 0;
        }
    }
}

#descricao{
    .description-container{
        text-align: center;
        margin-top: 30px;
    }
    .button {
        @include button-type-a(#000);
        margin: 0 auto;
        padding: 11px 0px;
        text-decoration: none;
        width: 160px;
    }
}

#destaques-1{
    a, a:hover, a:active{
        text-decoration: none;
        color: inherit;
    }
    .highlights-container.vo-carousel-load-fix{
        overflow: hidden;
        max-height: 280px;
    }
    .widget-highlights {
        margin-right: -15px;
    }
    .highlights-container {
        img {
            max-height: 125px;
        }
        &.slick-slider .slick-list {
            margin: 0 -30px 0 0;
            .slick-slide {
                margin-right: 15px;
                a {
                    display: block;
                    width: 255px;
                }
            }
        }
        .arrow{
            &.left{
                margin-left: 5px;
            }

            &.right{
                margin-right: 15px;
            }
        }
    }
}

.destaques-2 {
    background-color:#828282;
    padding: 40px 0;

    .items-container.vo-carousel-load-fix{
        overflow: hidden;
        max-height: 240px;
    }

    .sep{
        height: 1px;
        width:100%;
        background-color: #fff;
        margin: 20px auto;
    }

    h2{
        font-size: 18px;
        text-align: center;
        text-transform: uppercase;
        color: #fff;
    }

    h3{
        font-size: 25px;
        line-height: 28px;
        color: #fff;
        margin: 0 0 15px 0;
    }

    .img-container img {
        width: 67px;
        height: 67px;
        margin: 0 auto;
    }

    .description{
        margin-top:15px;
    }

    p{
        font-size: 14px;
        line-height: 18px;
        color: #fff;
    }
    a{
        text-decoration: none;
        .button.border-white.blue {
            width: 160px;
            height: 40px;
            border-style: solid;
            border-width: 1px;
            border-color: #fff;
            font-size: 11px;
            text-align: center;
            text-transform: uppercase;
            color: #fff;
            background-color: transparent;
            margin: 10px auto;
            padding: 11px 0;
        }
    }
    .arrow{
        @include arrow;
    }
}

.destaques-3{

    h2{
        font-size: 18px;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    h3{
        font-size: 18px;
        line-height: 22px;
    }

    .author-date{
        font-size: 14px;
        line-height: 18px;
        text-transform: uppercase;
        margin: 10px 0;
    }

    .description{
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 10px
    }

    li{
        padding: 30px 0;
        width: 100%;
        &.separator {
            border-bottom: solid #000 1px;
        }
    }

    section {
        .info-container a{
            text-decoration: none;
            .button{
                @include button-type-a(#000);
                display: inline-block;
                width: 100%;
            }
        }

        &.layout-a {
            .img-container {
                text-align: right;
                width: 40%;
                .circle-img {
                    width: 100%;
                    border-radius: 50%;
                }
            }

            .info-container{
                padding-left: 10px;
                width: 60%;
            }

        }

        &.layout-b{
            text-align: center;
            h2{
                text-align: left;
            }

            .img-container {
                text-align: center;
                .circle-img{
                    width:50%;
                    border-radius: 50%;
                }
            }

            .info-container {
                .description{
                    display: none;
                }
            }
        }
    }
}

.destaques-4 {
    background-color: #d9d9d9;
    padding: 38px 0;
    font-size: 14px;
    line-height: 18px;

    .items-container.vo-carousel-load-fix{
        overflow: hidden;
        max-height: 67px;
    }


    .arrow{
        @include arrow;
    }

    .img-container{
        float: left;
        margin-right: 10px;

        img{
            min-width: 60px;
            width: auto;
            height: auto;
            vertical-align: top;
        }
    }

    h2 {
        font-size: 14px;
        line-height: 18px;
    }

    .description{
        padding-top: 4px;
    }

    a, a:hover, a:active{
        text-decoration: none;
        color: inherit;
    }
}

.destaques-5 {
    background-color: #555555;
    padding: 40px 0;
    margin: 25px 0 40px 0;    
    
    .highlights-container.vo-carousel-load-fix{
        overflow: hidden;
        max-height: 240px;
    }
    .highlights-container {
        &.slick-slider .slick-list {
            .slick-slide {
                width: 223px;
            }
        }
        .arrow{
            &.left{
                margin-left: 5px;
            }

            &.right{
                margin-right: 15px;
            }
        }
    }
    h3{
        font-size: 25px;
        line-height: 28px;
        color: #fff;
        margin: 0 0 15px 0;
    }

    .img-container img {
        width: 67px;
        height: 67px;
        margin: 0 auto;
    }

    .description{
        margin-top:15px;
    }

    p{
        font-size: 14px;
        line-height: 18px;
        color: #fff;
    }
    a{
        text-decoration: none;
        .button.border-white.blue {
            width: 160px;
            height: 40px;
            border-style: solid;
            border-width: 1px;
            border-color: #fff;
            font-size: 11px;
            text-align: center;
            text-transform: uppercase;
            color: #fff;
            background-color: transparent;
            margin: 10px auto;
            padding: 11px 0;
        }
    }
    .arrow{
        @include arrow;
    }
}
/*
// Client asked for specific resolutions show top banner rotator smaller enough to make the second rotator visible
//320x568
@media all and (width: 320px), all and (max-device-height: 568px) {
    #rotator-cover-large .slider{
        max-height: 175px;
        overflow: hidden;
    }
}
*/